body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* 
.body-content {
  line-height: 2.5;
} */

footer {
  margin-top: 3em;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.body-content {
  overflow: ;
}

.body-content h3 {
  margin-top: 1em;
}

.body-content h4 {
  margin-top: 0.5em;
}

.link-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-decoration: underline;
  display: inline;
  margin: 0;
  padding: 0;
}

.link-button:hover, .link-button:focus {
  text-decoration: none;
}

#nav {
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 0;
  padding-bottom: 0;
}

.navbar-header .banner-image {
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 0;
  padding-bottom: 0;
  border: none;
}

.navbar-header .banner {
  max-height: 50px;
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 0;
  padding-bottom: 0;
  border: none;
}

.booking-option {
  margin-bottom: 2em;
}

.booking-option-title {
  font-size: larger;
  text-decoration: underline;
}

#nav.bg-primary {
  background-color: rgb(96, 0, 176) !important;
}

form.pay {
  width: 60vw;
  align-self: center;
  box-shadow: 0px 0px 0px 0.5px rgba(50, 50, 93, 0.1), 0px 2px 5px 0px rgba(50, 50, 93, 0.1), 0px 1px 1.5px 0px rgba(0, 0, 0, 0.07);
  border-radius: 7px;
  padding-left: 2em;
  padding-right: 2em;
}

input {
  border-radius: 6px;
  margin-bottom: 6px;
  padding: 12px;
  border: 1px solid rgba(50, 50, 93, 0.1);
  max-height: 44px;
  font-size: 16px;
  width: 100%;
  background: white;
  box-sizing: border-box;
}

.simple input {
  width: auto;
}

.simple input[type=checkbox]{
  margin-right:0.5em;
}

.card-error {
  color: red;
  font-size: 16px;
  line-height: 20px;
  margin-top: 12px;
  /* text-align: center; */
}

#card-element {
  border-radius: 4px 4px 0 0;
  padding: 12px;
  border: 1px solid rgba(50, 50, 93, 0.1);
  max-height: 44px;
  width: 100%;
  background: white;
  box-sizing: border-box;
}

#payment-request-button {
  margin-top: 1em;
  margin-bottom: 2em;
}

/* Buttons and links */

button:hover {
  filter: contrast(115%);
}

button:disabled {
  opacity: 0.5;
  cursor: default;
}

/* spinner/processing state, errors */

.spinner, .spinner:before, .spinner:after {
  border-radius: 50%;
}

.spinner {
  color: #ffffff;
  font-size: 22px;
  text-indent: -99999px;
  margin: 0px auto;
  position: relative;
  width: 20px;
  height: 20px;
  box-shadow: inset 0 0 0 2px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  display:inline-block;
  margin-right: 1em;
}

.spinner:before, .spinner:after {
  position: absolute;
  content: "";
}

.spinner:before {
  width: 10.4px;
  height: 20.4px;
  background: #5469d4;
  border-radius: 20.4px 0 0 20.4px;
  top: -0.2px;
  left: -0.2px;
  -webkit-transform-origin: 10.4px 10.2px;
  transform-origin: 10.4px 10.2px;
  -webkit-animation: loading 2s infinite ease 1.5s;
  animation: loading 2s infinite ease 1.5s;
}

.wrap-force
{
/* wrap long text and urls */
white-space: pre; /* CSS 2.0 */
white-space: pre-wrap; /* CSS 2.1 */
white-space: pre-line; /* CSS 3.0 */
white-space: -pre-wrap; /* Opera 4-6 */
white-space: -o-pre-wrap; /* Opera 7 */
white-space: -moz-pre-wrap; /* Mozilla */
word-wrap: break-word; /* IE 5+ */
word-break: break-all;
} 

.spinner:after {
  width: 10.4px;
  height: 10.2px;
  background: #5469d4;
  border-radius: 0 10.2px 10.2px 0;
  top: -0.1px;
  left: 10.2px;
  -webkit-transform-origin: 0px 10.2px;
  transform-origin: 0px 10.2px;
  -webkit-animation: loading 2s infinite ease;
  animation: loading 2s infinite ease;
}

@keyframes loading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@media only screen and (max-width: 600px) {
  form, form.pay {
    width: 80vw;
  }
}


.loading {
  /* https://css-tricks.com/considerations-styling-modal/ */
  background-color: white;
  position:fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 600px;
  max-width: 100%;
  
  max-height: 100%;

  padding: 1em;
  border-radius: 0.5em;
  border: 2px solid black;
  box-shadow: grey;

  z-index: 1020;

}
.loading-overlay {
  /* recommendation:
     don't focus on the number "1000" here, but rather,
     you should have a documented system for z-index and 
     follow that system. This number should be pretty
     high on the scale in that system.
  */
  z-index: 1000;

  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  background-color: rgba(0,0,0,0.4);

}


.card-picker {
  /* https://css-tricks.com/considerations-styling-modal/ */
  background-color: white;
  position:fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 600px;
  max-width: 100%;
  
  max-height: 100%;

  padding: 1em;
  border-radius: 0.5em;
  border: 2px solid black;
  box-shadow: grey;

  z-index: 1010;

  overflow: auto;

}
.card-picker-overlay {
  /* recommendation:
     don't focus on the number "1000" here, but rather,
     you should have a documented system for z-index and 
     follow that system. This number should be pretty
     high on the scale in that system.
  */
  z-index: 1000;

  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  background-color: rgba(0,0,0,0.4);

}
@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  .card-picker {
    width: 100%;
  }
}

/* https://github.com/christabor/css-progress-wizard/ */

.flexer, .progress-indicator {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex
}

.no-flexer, .progress-indicator.stacked {
  display: block
}

.no-flexer-element {
  -ms-flex: 0;
  -webkit-flex: 0;
  -moz-flex: 0;
  flex: 0
}

.flexer-element, .progress-indicator>li {
  -ms-flex: 1;
  -webkit-flex: 1;
  -moz-flex: 1;
  flex: 1
}

.progress-indicator {
  margin: 0 0 1em;
  padding: 0;
  font-size: 80%;
  text-transform: uppercase;
}

.progress-indicator>li {
  list-style: none;
  text-align: center;
  width: auto;
  padding: 0;
  margin: 0;
  position: relative;
  text-overflow: ellipsis;
  color: #bbb;
  display: block
}

.progress-indicator>li:hover {
  color: #6f6f6f
}

.progress-indicator>li.completed, .progress-indicator>li.completed .bubble {
  color: darkgreen;
}

.progress-indicator>li .bubble {
  border-radius: 1000px;
  width: 20px;
  height: 20px;
  background-color: #bbb;
  display: block;
  margin: 0 auto .5em;
  border-bottom: 1px solid #888
}

.progress-indicator>li .bubble:after, .progress-indicator>li .bubble:before {
  display: block;
  position: absolute;
  top: 9px;
  width: 100%;
  height: 3px;
  content: '';
  background-color: #bbb
}

.progress-indicator>li.completed .bubble, .progress-indicator>li.completed .bubble:after, .progress-indicator>li.completed .bubble:before {
  background-color: darkgreen;
  border-color: darkgreen
}

.progress-indicator>li.completed::after {
  font-family: "Font Awesome 5 Free";
  content: "\f058";
  margin-left: 0.5em;
}

.progress-indicator>li .bubble:before {
  left: 0
}

.progress-indicator>li .bubble:after {
  right: 0
}

.progress-indicator>li:first-child .bubble:after, .progress-indicator>li:first-child .bubble:before {
  width: 50%;
  margin-left: 50%
}

.progress-indicator>li:last-child .bubble:after, .progress-indicator>li:last-child .bubble:before {
  width: 50%;
  margin-right: 50%
}

.progress-indicator>li.active, .progress-indicator>li.active .bubble {
  color: green;
  font-weight: bold;
}

.progress-indicator>li.active .bubble, .progress-indicator>li.active .bubble:after, .progress-indicator>li.active .bubble:before {
  background-color: yellowgreen;
  border-color: yellowgreen
}

.progress-indicator>li a:hover .bubble, .progress-indicator>li a:hover .bubble:after, .progress-indicator>li a:hover .bubble:before {
  background-color: #5671d0;
  border-color: #1f306e
}

.progress-indicator>li a:hover .bubble {
  color: #5671d0
}

.progress-indicator>li.danger .bubble, .progress-indicator>li.danger .bubble:after, .progress-indicator>li.danger .bubble:before {
  background-color: #d3140f;
  border-color: #440605
}

.progress-indicator>li.danger .bubble {
  color: #d3140f
}

.progress-indicator>li.warning .bubble, .progress-indicator>li.warning .bubble:after, .progress-indicator>li.warning .bubble:before {
  background-color: #edb10a;
  border-color: #5a4304
}

.progress-indicator>li.warning .bubble {
  color: #edb10a
}

.progress-indicator>li.info .bubble, .progress-indicator>li.info .bubble:after, .progress-indicator>li.info .bubble:before {
  background-color: #5b32d6;
  border-color: #25135d
}

.progress-indicator>li.info .bubble {
  color: #5b32d6
}

.progress-indicator.stacked>li {
  text-indent: -10px;
  text-align: center;
  display: block
}

.progress-indicator.stacked>li .bubble:after, .progress-indicator.stacked>li .bubble:before {
  left: 50%;
  margin-left: -1.5px;
  width: 3px;
  height: 100%
}

.progress-indicator.stacked .stacked-text {
  position: relative;
  z-index: 10;
  top: 0;
  margin-left: 60%!important;
  width: 45%!important;
  display: inline-block;
  text-align: left;
  line-height: 1.2em
}

.progress-indicator.stacked>li a {
  border: none
}

.progress-indicator.stacked.nocenter>li .bubble {
  margin-left: 0;
  margin-right: 0
}

.progress-indicator.stacked.nocenter>li .bubble:after, .progress-indicator.stacked.nocenter>li .bubble:before {
  left: 10px
}

.progress-indicator.stacked.nocenter .stacked-text {
  width: auto!important;
  display: block;
  margin-left: 40px!important
}

@media handheld, screen and (max-width:400px) {
  .progress-indicator {
    font-size: 60%
  }
}

.saved-card-container{
  padding-top: 1em;
  padding-bottom: 1em;
}
.saved-card {
  box-shadow: 0px 0px 0px 0.5px rgba(50, 50, 93, 0.1), 0px 2px 5px 0px rgba(50, 50, 93, 0.1), 0px 1px 1.5px 0px rgba(0, 0, 0, 0.07);
  border-radius: 7px;
  padding-top: 1em;
  padding-bottom: 1em;
  padding-left: 1em;
  padding-right: 1em;
}

.card-image {
  display: inline-block;
  width: 32px;
  height: 22px;
  margin-right: 1em;
  background-image: url('images/cards/generic-card.png');
}

.card-image-amex {
  background-image: url('images/cards/american-express.png');
}

.card-image-diners {
  background-image: url('images/cards/diners-club.png');
}

.card-image-discover {
  background-image: url('images/cards/discover.png');
}

.card-image-mastercard {
  background-image: url('images/cards/mastercard.png');
}

/* .card-image-unionpay {
  background-image: url('images/cards/un');
} */

.card-image-visa {
  background-image: url('images/cards/visa-1.png');
}



.btn-default {
  color: #333;
  background-color: #fff;
  border-color: #ccc;
}


.card-line-2 {
  display: block;
}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  .card-line-2 {
    display: inline-block;
    margin-left: 1em;
  }
}

small.form-text{
  display:block;
}

label {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
  font-weight: bold;
}

form input, form select {
  max-width: 400px;
}
form textarea {
  max-width: 600px;
}
form input[type=submit] {
  width: auto;
}





#cookie-banner {
  background-color: white;
  border: 2px solid #bc2be1;
  padding: 2em;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100000000;
}
#cookie-accept-button {
  margin-top: 1em;
}
.hidden {
  visibility: collapse; 
}
